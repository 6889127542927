.stats div{
 display: flex;
 color: #fff;
 align-items: baseline;

}
.stat{
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.stat p{
    margin-right: 20px;
    font-size: 1.7rem;
}

.stat h6{
   color:rgb(20, 192, 218);
}

@media(max-width:768px){
    .stat{
       flex-wrap: wrap;
    }
}