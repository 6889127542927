.test_header{
    display: flex;
    align-items: center;
   gap: 25rem;
   margin-top: 70px!important;
   justify-content: center;
}
.test_header h4{
    font-size: 2rem;
    color: #fff;
}

.test_header p{
    font-size: 1rem;
    color:rgba(255, 255, 255, 0.7);
}

.card_profile{
    display: flex;
    align-items: center;
    margin-top: 30px;
    
    
}
.Card{
    background:  rgba(20, 192, 218,0.2);;
    margin-bottom: 20px;
    padding: 20px;
    border-radius: 10px;
}

.card_profile img{
    width: 48px;
    height: 48px;
    margin-right: 10px;
    margin-bottom: 10px;
}
.card_profile_data h4{
font-size: 1rem;
color: #fff;

}
.card_profile_data p{
    font-size: 1rem;
    color:rgba(255, 255, 255, 0.7);
    
    }

.card_profile_data{
    display: flex;
    flex-direction: column;

  
}

@media (max-width:768px){
    .test_header{
       flex-wrap: wrap;
       gap: 3rem;
    }
}

@media(max-width:810px) {
    .test_header{
       
        gap: 3rem;
     }
}