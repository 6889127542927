.billing_img img{
    width: 100%;
    animation: tofro 1s infinite  alternate;
}
@keyframes tofro {
    0%{
        transform: translateY(20px);
    }
    100%{
        transform: translateY(-20px);
    }
}

.billing_content{
    margin-top: 40px;
    position: relative;
}
.billing_content h4{
    color: #ffff;
    font-size: 3rem;
}

/* .shadow{
   
    position: absolute;
width: 200px;
height: 200px;
left: 0px;
top: -200px;
z-index: -1;
background: linear-gradient(180deg, rgba(188, 165, 255, 0) 0%, #214D76 100%);
border: 1px solid #000000;
filter: blur(61.5px);
} */

.billing_content p{
    color: #ffff;
    color:rgba(255, 255, 255, 0.7);
    margin-top: 20px;
}
.buttons {
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    gap: 2rem;
}


@media (max-width:600px) {
    .buttons{
        flex-wrap: wrap;
    }
    .billing_content h4{
        color: #ffff;
        font-size: 2rem;
    }

}
