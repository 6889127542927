.Navbar{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2rem 0rem;
}


.nav__left img{
    width: 120px;
}

.nav__right{
    display: flex;
    cursor: pointer;
}


.nav__right ul li{
    list-style: none;
 
    color: #ffff;
}
.icon{
    color: #ffff;
    cursor: pointer;
   font-size: 1.5rem;
}

@media(max-width:768px){
    .nav__right{
        position: fixed;
        flex-direction: column;
        right: 0rem;
        top: 30px;
        padding-right: 20px;
    }
}