.logo{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.logo img{
    width: 10rem;
}

.logo p{
    color:rgba(255, 255, 255, 0.7);
}

.footer_links{
    color: #fff;
    display:flex ;
    flex-direction: column;
    align-items: start;
    

}

.footer_links ul li{
    list-style: none;
    cursor: pointer;
    margin-top: 5px;
    margin-left:-30px ;
    color:rgba(255, 255, 255, 0.7);
}

.footer_links .title{
   margin-bottom: 20px;
   font-size: 1.2rem;
   cursor: pointer;
}

.footer_icons{
    display: flex;
    justify-content:end;
    gap: 2rem;
    cursor: pointer;
    margin-bottom: 20px;
}

.footer_copyright p{
    color: #fff;
}


@media (max-width:768px){
    .footer_icons{
        justify-content:center;
        align-items: center;
       
    }
    .footer{
        display: flex!important;
        justify-content: center!important;
        align-items:center !important;

    }
}