.f_left{
    color: #fff;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 50px;
}
.f_left h3{
    font-size: 3rem;
}
.f_left p{
    font-size: 1rem;
    color:rgba(255, 255, 255, 0.7);
    ;
}

.f_right{
    display: flex;
    margin-top: 50px;
    gap: 0.8rem;
    color: #fff;
    align-items: center;
    justify-content: center;
    background: rgba(20, 192, 218,0.2);
    padding: 15px;
    border-radius: 5px;
  
   
}
.f_right:hover{
  
}
.f_right .img{
    color:rgb(20, 192, 218) ;
    font-size: 2.2rem;
    padding-right: 10px;
}

.f_right .content p{
    color:rgba(255, 255, 255, 0.7);
    font-size: 1rem;

}

.f_right h4{
    font-size: 1.2rem;
}

@media(max-width:600px){
    .f_left h3{
        font-size: 2rem;
    }
}