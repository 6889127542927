/* .brands{
    display: inline-flex!important;
    align-items: center;
 gap: 2rem;
} */

.brands img{
    width: 192.25px;
}

.newsLetter{
    display: flex;
    justify-content: space-around;
    background:  rgba(20, 192, 218,0.2);
    padding: 2rem;
    border-radius: 10px;
}
.newsLetter h4{
   font-size: 3rem;
   color: #fff;
}
.newsLetter p{
    font-size: 1rem;
    color:rgba(255, 255, 255, 0.7);
 }

@media (max-width:768px) {
    .brand{
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;

    }
    .brands img{
        width: 160px;
        margin-bottom: 40px;
       


    }
    .newsLetter{
       flex-wrap: wrap;
    }
    .newsLetter h4{
    font-size: 2rem;
     }
    
}