.card_img img{
    width: 100%;
    animation: tofro 4s infinite  alternate;
}
@keyframes tofro {
    0%{
        transform: translateY(20px);
    }
    100%{
        transform: translateY(-20px);
    }
}

.card_content h4{
    font-size: 3rem;
    color: #fff;
    margin-bottom: 10px;
}
.card_content {
    margin-top: 40px;
}

.card_content p{
    font-size: 1rem;
    color:rgba(255, 255, 255, 0.7);
    margin-bottom: 10px;
    margin-top: 30px;
}

@media (max-width:600px) {
    .card_content h4{
        font-size: 2rem;
       
    }
}