.hero__right img{
    width: 100%;
    animation: tofro 3s infinite  alternate;
}
.hero__right{
    position: relative;
}
.hero__left{
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    position: relative;
    border: 0px solid white;
}
.hero__left p{
    color: rgba(255, 255, 255, 0.7);
    
}
.hero__left .get_started_btn{
    width: 150px!important;
    height: 150px;
    border: 3px solid  rgb(20, 192, 218);
    border-radius: 50%!important;
    background: transparent!important;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.2rem;
    align-self: flex-end;
    position: absolute;
    animation: tofro 3s infinite  alternate;
 
}

@keyframes tofro {
    0%{
        transform: translateY(20px);
    }
    100%{
        transform: translateY(-20px);
    }
}
.hero__left h2{
    font-size: 3.7rem;
}
.hero__left h2 span{
    /* background: linear-gradient(90deg, rgba(21,105,118,1) 7%, rgba(51,187,207,1) 100%); */
    background: -webkit-linear-gradient(90deg, rgb(20, 192, 218) 7%, rgba(51,187,207,1) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
 
}

.hero__left > :nth-child(1){
    background: rgb(30, 26, 26);
    width: fit-content;
    padding: 0.3rem;
    text-transform: uppercase;
    border-radius: 5px;
}

.shadow{
   
    position: absolute;
width: 500px;
height: 571px;
left: 40px;
top: -130px;
z-index: -1;
background: linear-gradient(180deg, rgba(188, 165, 255, 0) 0%, #214D76 100%);
border: 1px solid #000000;
filter: blur(61.5px);
}

@media(max-width:768px){
    .get_started_btn{
        display: none !important;
    }
    .hero__left h2{
        font-size: 2.5rem;
    }
    .shadow{
        display: none;
    }
    .hero__right img{
        margin-top: 30px;
    }
}